
<template>
    <b-container>
      <b-input-group size="lg" class="my-4">
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="search"></font-awesome-icon>
        </b-input-group-prepend>
        <b-input type="search" v-model="zoekterm" debounce="500" placeholder="Zoeken..." ></b-input>
      </b-input-group>
      <b-radio-group  v-model="mode" :options="[{text:'Tegels',value: 'Cards'}, {text:'Kaart',value: 'Map'}]"></b-radio-group>

      <div class="my-grid my-4" v-if="mode=='Cards'">
        <b-card v-for="u in filteredUnits" no-body :key="u.RemoteId">
          <router-link :to="{name: 'Unit',params: {remoteid: u.RemoteId}}">
          <unit-card-image :unitImage="u.Thumbnail" :complexImage="u.Complex.Thumbnail"></unit-card-image>
          <b-card-body>
          {{u.UnitKenmerk}}<br/>{{u.Adres}} {{u.WijkPlaats}}
          </b-card-body>
          </router-link>
          <b-card-footer>
            <b-button variant="ghost" size="sm" class="float-right" :to="{name: 'Unit',params: {remoteid: u.RemoteId}}">Ga naar unit <font-awesome-icon icon="arrow-right"></font-awesome-icon></b-button>
          </b-card-footer>
        </b-card>
        </div>
        <div v-else-if="mode == 'Map'">
          <kaart :units="filteredUnits" :center="centermap" :zoom="profiel.map ? profiel.map.zoom : 7"></kaart>
        </div>

    </b-container>
</template>
<script>
import { db, auth } from '../firebase'
import UnitCardImage from '../components/UnitCardImage.vue'
import Kaart from '../components/Kaart.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    UnitCardImage,
    Kaart
  },
  data () {
    return {
      mode: 'Cards',
      profiel: null,
      units: [],
      zoekterm: null
    }
  },
  computed: {
    filteredUnits: function () {
      var z = this.zoekterm
      if (z == null) return this.units
      return this.units.filter(u => u.Adres.toLowerCase().includes(z.toLowerCase()) || u.UnitKenmerk.toLowerCase().includes(z.toLowerCase()) || u.WijkPlaats.toLowerCase().includes(z.toLowerCase()) || u.Onderhoudsnummer.toLowerCase().includes(z.toLowerCase()))
    },
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    centermap: function () {
      if (!this.profiel) return null
      if (!this.profiel.map) return { lat: 52.457469185987094, lng: 4.599601239909644 }
      return { lat: this.profiel.map.center.latitude, lng: this.profiel.map.center.longitude }
    }
  },
  mounted () {
    this.getProfiel()
  },
  watch: {
    'profiel.adviesnummer': {
      // call it upon creation too
      handler (adviesnummer) {
        this.$bind('units', db.collection('units').where('Complex.Relatie.Adviesnummer', '==', adviesnummer).where('HuidigeStatus', '!=', 0).orderBy('HuidigeStatus').orderBy('UnitKenmerk'))
      }
    }
  },
  methods: {
    rowClicked (row, index) {
      console.log(index)
      this.$set(row, '_showDetails', !row._showDetails)
    },
    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db.collection('users').doc(this.userId).get().then(snapshot => {
        vm.profiel = snapshot.data()
      })
    }
  }
}
</script>
<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  /* 280px is the minimum a column can get, you might need to adjust it based on your needs. */
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 1.5rem;
}

.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
</style>
