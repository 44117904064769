<template>
<b-container>
    <b-button-toolbar justify class="mb-4">
    </b-button-toolbar>
        <b-table :items="units" :fields="[{label:'Unit',key:'unit.UnitKenmerk'}]">
        </b-table>

    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { db, auth } from '../firebase'

export default {
  name: 'AdminPagina',
  data () {
    return {
      users: [],
      profiel: null,
      units: [],
      elementen: null
    }
  },
  firestore: {
    users: db.collection('users')
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    }
  },
  mounted () {
    this.getProfiel()
  },
  methods: {
    WriteFetchedUnits () {
      this.units.forEach((u) => this.WriteUnit(u))
      // for (var u in this.units) this.WriteUnit(u)
    },
    WriteUnit (data) {
      console.log(data)
      var unitData = data
      var unitID = unitData.unit.RemoteId
      db.collection('units').doc(unitID).set(unitData.unit).then(() => {
        db.collection('units').doc(unitID).collection('prognoses').doc('HuidigePrognose').set(unitData.prognose)
        unitData.documenten.forEach(dc => {
          db.collection('units').doc(unitID).collection('documenten').doc(dc.FileMetadata.asset_id).set(dc)
        })
      })
    },

    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db.collection('users').doc(this.userId).get().then(snapshot => {
        vm.profiel = snapshot.data()
        // do something with document
      })
    }
  }
}
</script>
